@font-face{font-family:'Lato';font-style:normal;font-weight:400;font-display:swap;src:url(../fonts/S6uyw4BMUTPHjx4wXg.woff2) format("woff2");unicode-range:U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD}
@font-face{font-family:'Lato';font-style:normal;font-weight:700;font-display:swap;src:url(../fonts/S6u9w4BMUTPHh6UVSwiPGQ.woff2) format("woff2");unicode-range:U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD}
@font-face{font-family:'Lato';font-style:normal;font-weight:900;font-display:swap;src:url(../fonts/S6u9w4BMUTPHh50XSwaPGR_p.woff2) format("woff2");unicode-range:U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF}
body{line-height:1;font-family:'Lato',sans-serif;background-color:#eee}
a{color:#026ab2;text-decoration:underline}
.header_module{padding:10px 0 12px}
.header_module.front_page{padding:7px 0 47px}
.breadcrumbs_module ul{padding-left:0;list-style:none;margin-bottom:0}
.breadcrumbs_module ul li{display:inline-block;position:relative;padding-right:4px;margin-right:10px;font-size:16px;font-style:italic;font-weight:400;line-height:26px}
.breadcrumbs_module ul li+li:after{position:absolute;content:">";top:0;left:-12px}
.pi_posr{position:relative}
.pi_abs{position:absolute;top:86px;right:25%;z-index:-1}
.page_intro_module{padding-bottom:82px}
.posr{position:relative}
.career_module h2{font-size:25px;line-height:37px;font-weight:700;color:#2d2d2d;margin-bottom:46px;margin-top:30px}
.career_module h4{font-size:26px;color:#2d2d2d;font-weight:700;line-height:38px}
.cm_img{height:95px;margin-bottom:25px}
.cm_img img{vertical-align:bottom}
.cbb_right{background-color:rgba(187,1,6,0.8)}
.cbb_right h2{font-size:31px;color:#fdbb16;font-weight:700;line-height:41px;margin-bottom:5px;text-align:center}
.cbb_right{padding:46px 10px 83px 38px}
.cbb_right form{padding-top:7px}
.cbb_right form input[type="text"],.cbb_right form input[type="tel"],.cbb_right form input[type="email"],.cbb_right form select{background:transparent;box-shadow:none;border:none;color:rgba(244,244,244,.7);font-size:18px;width:100%;border-bottom:1px solid rgba(244,244,244,.7);padding:8px 2px 0;outline:none}
.cbb_right form input::-webkit-input-placeholder{color:rgba(244,244,244,.7)}
.cbb_right form input:-moz-placeholder{color:rgba(244,244,244,.7)}
.cbb_right form input::-moz-placeholder{color:rgba(244,244,244,.7)}
.cbb_right form input:-ms-input-placeholder{color:rgba(244,244,244,.7)}
.cbb_right form input::-ms-input-placeholder{color:rgba(244,244,244,.7)}
.cbb_right form input::placeholder{color:rgba(244,244,244,.7)}
.cbb_right form select::-ms-expand{display:none}
.cbb_right form select option{color:#2d2d2d}
.cbbr-field{margin-bottom:10px;margin-right:35px}
.f_cnt{padding-top:20px;color:rgba(244,244,244,.7);font-style:italic;font-size:14px;line-height:18px;padding-left:8px}
.cbb_right form input[type="submit"]{color:#f4f4f4;font-size:24px;line-height:24px;padding:12px 89px 12px 100px;background-color:#181818;font-weight:700;border:none;margin:10px auto;display:block}
.cbb_right form input[type="submit"]:hover,.cbb_right form input[type="submit"]:focus{background-color:#ee482e}
.cm_cnt{position:relative}
.cn_round{position:absolute;top:20px;z-index:-1;left:200px}
.clearfix:after{display:block;content:"";clear:both}
.whoare_module{margin-top:175px;padding-top:186px}
.whoare_module h2{font-size:65px;color:#2d2d2d;margin-bottom:38px;text-transform:uppercase;line-height:65px;font-weight:700}
.whoare_module p{font-size:22px;line-height:32px;color:#2d2d2d;font-weight:400}
.wmb_conts{margin-right:45px;padding-right:60px;padding-bottom:50px;margin-bottom:82px}
.whoare_module .wmb_link{font-size:26px;line-height:42px;font-weight:400;color:#383838}
.whoare_module .wmb_link a{color:#026ab2;text-decoration:underline}
.whoare_module .wmb_link a:hover{text-decoration:none}
.wm_block{background-image:url(../img/who_we_are_shadow.png);background-repeat:no-repeat;background-size:cover}
.pm_block{background-color:#fdbb16;padding-left:40px;padding-top:60px;padding-bottom:48px}
.programe_module h2{font-size:65px;color:#f4f4f4;font-weight:700;text-transform:uppercase}
.programe_module p{font-size:22px;line-height:32px;color:#2d2d2d;font-weight:400;margin-bottom:50px}
.pb_blog h3{font-size:36px;color:#c53036;font-weight:700;line-height:36px}
.pb_blog p{font-size:20px;line-height:30px;color:#383838;font-weight:400;margin-bottom:22px}
.pb_blog ul{list-style:none;padding-left:0}
.pb_blog ul li{font-size:18px;line-height:1.5;color:#383838;font-weight:400;background-image:url(../img/lists_arrow.png);background-repeat:no-repeat;background-size:13px 18px;background-position:top 4px left;padding-left:28px;margin-bottom:10px}
.btn-red{margin-top:100px;display:inline-block;padding:20px 36px;background:linear-gradient(90deg,rgba(173,32,36,1) 0%,rgba(238,78,41,1) 60%);font-size:20px;color:#f4f4f4;font-weight:700;text-transform:uppercase;margin-bottom:10px;text-decoration:none}
.btn-red:hover{background:linear-gradient(90deg,rgba(238,78,41,1) 0%,rgba(173,32,36,1) 60%);color:#fff;text-decoration:none}
.programe_module{margin-bottom:108px}
.pm_corner{position:absolute;bottom:-108px;right:66px;content:'';width:420px;height:420px}
.am_block h2{font-size:56px;color:#2d2d2d;font-weight:700;text-transform:uppercase;line-height:66px;text-align:center;margin-bottom:20px}
.advantage_module ul{list-style:none;padding-left:0}
.advantage_module ul li{font-size:18px;line-height:30px;color:#383838;font-weight:400;background-image:url(../img/true_arrow.png);background-repeat:no-repeat;background-size:23px 25px;background-position:top 8px left;padding-left:34px;padding-bottom:15px;text-align:left}
.am_btn{margin-top:16px;display:inline-block;padding:20px 36px;background:linear-gradient(90deg,rgba(173,32,36,1) 0%,rgba(238,78,41,1) 60%);font-size:20px;color:#f4f4f4;font-weight:700;text-transform:uppercase;margin-bottom:10px;text-decoration:none}
.am_btn:hover{background:linear-gradient(90deg,rgba(238,78,41,1) 0%,rgba(173,32,36,1) 60%);color:#fff;text-decoration:none}
.advantage_module{padding:132px 0;margin-bottom:116px}
.am_blog{padding-top:58px;margin-bottom:161px}
.footer_module{background-color:#fdfdfd;padding-top:45px;padding-bottom:30px}
.footer_module ul{padding-left:0;list-style:none;margin-bottom:0}
.fm_menu ul li{display:block;margin-bottom:30px}
.fm_menu ul li a{font-size:17px;line-height:20px;text-decoration:underline;color:#2e2e2e;font-weight:400}
.fmb_social ul li a{color:#424242;font-size:25px;line-height:50px}
.fmb_social h3{font-size:20px;line-height:20px;color:#2e2e2e;font-weight:400;margin-bottom:32px}
.fmb_social ul li{width:50px;height:50px;display:inline-block;text-align:center;border:1px solid #424242;border-radius:50%;margin:0 5px}
.fmb_social ul li:nth-child(1){margin-left:0}
.fmb_social ul li:nth-child(4){margin-right:0}
.fm_copyright{padding-top:112px}
.fm_copyright p{font-size:14px;color:#2e2e2e;font-weight:400;margin-bottom:0}
.fmb_social ul li:hover{border-color:rgba(173,32,36,1);background-color:rgba(173,32,36,1)}
.fmb_social ul li:hover a{color:#fff}
.fmb_social ul li a:hover{color:#fff}
.fm_menu ul li a:hover{color:rgba(173,32,36,1);text-decoration:none}
.fmb_link{display:inline-block}
.pm_blog{position:relative}
.benefits_lists ul{padding-left:0;list-style:none}
.benefits_lists ul li{display:block}
.hm_btn a{margin-top:16px;display:inline-block;padding:15px 9px 16px 11px;margin-bottom:10px;font-size:17px;color:#f4f4f4;font-weight:700;box-shadow:-3.364px 3.364px 5px rgba(8,8,8,0.25);background-image:linear-gradient(90deg,#ab1f24 0%,#f04e29 100%);text-decoration:none}
.hm_btn a:hover{background-image:linear-gradient(90deg,#f04e29 0%,#ab1f24 100%)}
.hm_phone{margin-left:auto}
.hm_phone span{font-size:24px;line-height:28px;color:#2d2d2d;font-weight:400}
.hm_phone span a{color:#2d2d2d}
.hm_phone img{margin-right:9px;vertical-align:bottom}
.hm_btn a{margin:0 68px 0 66px}
.cbb_right{position:relative}
.cbb_right:after{position:absolute;content:'';width:130%;height:31px;background-image:url(../img/shadow_line.png);top:-8px;right:0;z-index:9}
.hm_phone a{display:inline-block;text-decoration:none}
.hm_phone a:hover,.hm_phone a:focus{color:#c53036;text-decoration:none}
.hm_phone a:hover span{color:#c53036}
.navbar{transition:all .6s;backface-visibility:hidden;padding:0}
.navbar-toggler{padding:0;border:0}
.navbar-toggler:focus{background:none;outline:0}
.navbar-toggler span{display:block;width:32px;height:4px;margin-bottom:10px;background:#484847;transition:all .6s cubic-bezier(0.250,0.100,0.250,1.000)}
.navbar-toggler span:last-child{margin-bottom:0}
.navbar-toggler:hover span,.navbar-toggler:focus span{background:#484847}
.navbar-toggler[aria-expanded="true"] span:first-of-type{transform:rotate(45deg) translate(10px,10px)}
.navbar-toggler[aria-expanded="true"] span:nth-of-type(2){opacity:0}
.navbar-toggler[aria-expanded="true"] span:last-of-type{transform:rotate(-45deg) translate(9px,-9px)}
.navbar-toggler[aria-expanded="false"] span{transform:none;opacity:1}
.navbar-nav .nav-link{padding:0}
.navbar-nav .nav-item{border-bottom:2px solid #fff}
.navbar-nav .nav-item:last-child{border-bottom:0}
.navbar-nav .nav-item a{padding:15px;color:#000;text-decoration:none;padding-top:0}
.navbar-nav{background:#ffff;margin-top:20px}
.navbar-nav .dropdown-menu{padding:0;border:0;background:#ab1f24;border-top:2px solid #fff;border-radius:0;margin-top:0}
.navbar-nav .dropdown-menu .nav-item a{padding-left:30px}
.pi_heading{font-size:60px;color:#2d2d2d;font-weight:400;line-height:70px;text-transform:uppercase}
.pi_content{margin-top:100px;max-width:88%}
.pi_content h3{font-size:33px;color:#2d2d2d;font-weight:700;line-height:88px;margin-bottom:20px}
.am_block{padding:28px 0 43px}
.am_block p{font-size:21px;line-height:31px;color:#2d2d2d;font-weight:400}
.benefits-text{font-size:26px;line-height:36px;color:#000;font-weight:700}
.dv-btn{display:none}
.mv-btn{display:block}
.am_small p{font-size:13px;font-style:italic;font-weight:400;color:#444;line-height:1.5}
.fm_copyright p{font-size:14px;line-height:1.5}
.programe_module{margin-bottom:0}
.amb_cnt p{font-size:17px;line-height:1.5}
.amb_cnt p:last-child{margin-bottom:0}
#return-to-top{position:fixed;bottom:85px;right:20px;background:#000;background:rgba(0,0,0,1);width:40px;height:40px;display:block;text-decoration:none;display:none;-webkit-border-radius:0;-moz-border-radius:0;border-radius:0;-webkit-transition:all .3s linear;-moz-transition:all .3s ease;-ms-transition:all .3s ease;-o-transition:all .3s ease;transition:all .3s ease}
#return-to-top i{color:#fff;margin:0;position:relative;left:12px;top:6px;font-size:16px;-webkit-transition:all .3s ease;-moz-transition:all .3s ease;-ms-transition:all .3s ease;-o-transition:all .3s ease;transition:all .3s ease}
#return-to-top:hover{background:rgba(0,0,0,0.9)}
#return-to-top:hover i{color:#fff;top:3px}
.header_module{background:#eee;color:#aaa;padding:10px 0 12px;position:-webkit-sticky;position:sticky;top:0;transition:all .4s ease-in-out;-webkit-transition:all .4s ease-in-out;-moz-transition:all .4s ease-in-out;width:100%;z-index:9999}
.header_module.shrink{padding:10px 0!important}
.logo_img{transition:all .4s ease-in-out;-webkit-transition:all .4s ease-in-out;-moz-transition:all .4s ease-in-out}
.shrink .logo_img{width:50%}
.logout_icon i{font-size:34px;cursor:pointer}
.logout_icon{position:relative}
.drop_logout{position:absolute;min-width:120px;border:1px solid;right:0;background:#fff;display:none}
.drop_logout li{padding:10px;width:100%;box-shadow:-6.364px 6.364px 4px #140000;background-image:linear-gradient(90deg,#ab1f24 0%,#f04e29 100%);color:#fff;cursor:pointer}
.logout_icon:hover .drop_logout{display:block}
.drop_logout li:hover{background-image:linear-gradient(90deg,#f04e29 0%,#ab1f24 100%)}
.drop_logout li a{text-decoration:none;color:#fff}
body,html{width:100%;height:100%}
body{overflow-x:hidden}
#root,.main_wrapper{height:100%}
.main_wrapper{display:flex;flex-direction:column}
.footer_module{margin-top:auto}
.login_inner{background:#fdbb16;width:500px;height:auto;margin:auto;margin-bottom:30px;padding:50px}
.login_inner input{margin-bottom:30px;border-radius:0}
.login_inner input[type="submit"]{margin-bottom:0;border-radius:20px;color:#006bb6}
.thank_you{background:#fdbb16;text-align:center;color:#221b1b;padding:50px 20px;margin-bottom:50px}
.thank_you h1{margin-bottom:10px;font-size:30px;font-weight:700;font-family:'Lato'}
.thank_you h5{margin-bottom:10px;font-size:20px;font-weight:600;font-family:'Lato'}
.thank_you p{margin-bottom:10px;font-size:16px;font-weight:600;font-family:'Lato'}
.loginErrorMsg{text-align:center;margin-bottom:20px;color:red;font-weight:600}
.inputError{text-align:left;margin-bottom:0;color:#fdbb16;font-weight:500;font-size:14px;margin-top:5px;display:block}
.front_page.shrink{position:fixed;width:100%}
.front_page{border-bottom:0}
#reload_href{cursor:pointer;color:#fdbb16!important;text-align:left;display:block}
.pleaseWait{color:#fdbb16;text-align:center;font-weight:600}
.alert_div{text-align:center;margin-bottom:20px;color:red;font-weight:600}
.reload-captcha canvas{width:120px}
table td{font-size:14px}
.table-bordered thead th{border-bottom-width:1px}
.Ontoggle{display:none}
.collapse-area{background:#D7D8DC}
.panel{margin-top:0!important;border-radius:0!important;border:none;border-bottom-color:#D7D8DC;box-shadow:0 0 0 0 transparent!important;-moz-box-shadow:0 0 0 0 transparent!important;-webkit-box-shadow:0 0 0 0 transparent!important;-o-box-shadow:0 0 0 0 transparent!important}
.panel .panel-heading{border-radius:0!important;background:#f5f6f8;padding:0!important;border-bottom:0 solid #DDDEE2}
.panel .panel-heading .panel-title a{text-decoration:none;font-weight:700;display:block;padding:23px 15px;font-weight:300;color:#60626d;background-color:#fff;line-height:29px;position:relative}
.panel .panel-heading .panel-title a:hover,.panel .panel-heading .panel-title a:active{text-decoration:none;cursor:pointer;transition:all .4s;-moz-transition:all .4s;-webkit-transition:all .4s;-o-transition:all .4s;color:#9a4a5a}
.panel .panel-heading .panel-title a span{float:left;margin-top:15px;margin-right:30px;transition:all .4s;-moz-transition:all .4s;-webkit-transition:all .4s;-o-transition:all .4s}
.panel .panel-heading .panel-title a .bar,.panel .panel-heading .panel-title a .bar:after{border-width:1px;border-style:solid;width:21px;border-radius:10px;transition:all .4s;-moz-transition:all .4s;-webkit-transition:all .4s;-o-transition:all .4s}
.panel .panel-heading .panel-title a .bar:after{content:"";height:0;position:absolute;top:38px;left:15px}
.panel .panel-heading .panel-title a.collapsed{background-color:#f5f6f8!important;border-bottom:1px solid #eeeef3;position:relative;transition:all .4s;-moz-transition:all .4s;-webkit-transition:all .4s;-o-transition:all .4s}
.panel .panel-heading .panel-title a.collapsed .bar{border-color:#75767F}
.panel .panel-heading .panel-title a.collapsed .bar:after{transform:rotate(90deg);-webkit-transform:rotate(90deg);-moz-transform:rotate(90deg);-o-transform:rotate(90deg);border-color:#75767F;transition:all .4s;-moz-transition:all .4s;-webkit-transition:all .4s;-o-transition:all .4s}
.panel-collapse{background-color:#fff}
.panel-collapse .panel-body{background-color:#fff;border:0 solid!important;line-height:26px;font-weight:300;margin-left:50px;padding-bottom:20px;padding-top:0;color:#60626d}
@media (min-width: 768px) {
.logo_img{width:70%}
.fm_menu ul li a{font-size:14px;line-height:17px}
.fmb_social ul li{width:30px;height:30px;margin:0 3px}
.fmb_social ul li a{font-size:17px;line-height:30px}
.cn_round{left:0}
.cbb_right{padding:25px 10px}
.cbb_right h2{margin-top:0}
.cbbr-field{margin-right:0}
.career_module h2{font-size:20px;line-height:32px}
.career_module h4{font-size:15px;line-height:1.5}
.cbb_right form input[type="submit"]{padding:12px;width:95%;font-size:20px;line-height:20px}
.whoare_module{margin-top:40px;padding-top:100px}
.whoare_module h2{font-size:45px;line-height:45px;margin-bottom:20px}
.whoare_module .wmb_link{font-size:20px;line-height:1.5}
.wmb_conts{margin-right:25px;padding-right:50px}
.wm_block{background-size:100% 100%;background-position:right bottom}
.programe_module h2{font-size:45px;line-height:1.5}
.pb_blog h3{font-size:32px;line-height:32px}
.pb_blog ul li{font-size:16px;line-height:1.5}
.pb_blog p{font-size:16px;line-height:1.5}
.pm_corner img{position:absolute}
.btn-red{margin-top:40px;padding:16px 6px;font-size:14px}
.whoare_module p{font-size:17px;line-height:1.5}
.am_block h2{font-size:45px;line-height:55px}
.am_blog{padding-top:30px}
.advantage_module ul li{font-size:16px;line-height:26px}
.am_btn{font-size:15px;padding:14px 30px}
.advantage_module{padding:80px 0 39px;margin-bottom:0}
.fm_copyright{padding-top:40px}
.cn_round{left:50px;right:50px}
.pm_corner{bottom:-74px;right:-24px;width:320px;height:320px;overflow-x:hidden}
.hm_btn a{margin:0 25px;padding:6px 15px 9px 16px}
.hm_phone span{font-size:20px}
.am_blog{margin-bottom:110px}
.am_small p{line-height:1.5;font-size:13px}
.programe_module p{font-size:17px;line-height:1.5}
.cbb_right form input[type="text"],.cbb_right form input[type="tel"],.cbb_right form input[type="email"],.cbb_right form select{font-size:15px}
.fm_logo{width:80%}
.fm_menu ul li{margin-bottom:20px}
.cm_img{height:50px;margin-bottom:15px}
.cm_img img{height:50px}
.cm_img_1 img,.cm_img_2 img{padding-left:20px}
.am_block{padding:28px 0 15px}
.cbb_right h2{font-size:20px;line-height:1.5;text-align:center}
.dv-wm{display:block}
.mv-wm{display:none}
.pi_banner{display:none}
}
@media (min-width: 992px) {
.cbb_right form input[type="submit"]{padding:12px;width:100%;font-size:24px;line-height:24px}
.wm_block{background-size:contain;background-position:right bottom 40px}
.btn-red{font-size:15px;margin-top:100px;padding:20px 36px}
.pm_corner{bottom:-85px;right:-26px;width:330px;height:330px;overflow-x:hidden}
.pm_corner img{position:absolute}
.pb_blog ul li{font-size:15px;line-height:22px}
.pb_blog p{font-size:17px;line-height:27px}
.cbb_right h2{font-size:26px;line-height:36px}
.career_module h2{font-size:22px;line-height:34px}
.am_block h2{font-size:45px}
.am_btn{font-size:15px;margin-top:16px;padding:14px 36px}
.advantage_module ul li{font-size:16px;line-height:26px}
.fm_menu ul li a{font-size:15px;line-height:20px}
.fmb_social ul li{width:40px;height:40px;margin:0 5px}
.fmb_social ul li a{font-size:22px;line-height:40px}
.career_module h4{font-size:21px;line-height:1.5}
.whoare_module{margin-top:75px;padding-top:140px}
.wmb_conts{margin-right:45px}
.hm_phone span{font-size:24px}
.hm_btn a{margin:0 49px;padding:7px 15px 9px}
.whoare_module .wmb_link{font-size:22px;line-height:38px}
.amb_left,.amb_right{margin-top:50px}
.am_small p{font-size:13px;font-style:italic;font-weight:400;color:#444;line-height:1.5}
.cn_round{left:140px;right:140px}
.cm_img_1 img,.cm_img_2 img{padding-left:25px}
.cm_img{height:60px;margin-bottom:15px}
.cm_img img{height:60px}
.dv-btn{display:inline-block}
.mv-btn{display:none}
.cbb_right{padding:35px 10px 45px}
.programe_module{margin-bottom:0}
.amb_cnt{padding-right:15px}
.pi_content h3{font-size:26px;line-height:10px}
}
@media (min-width:1200px) {
.logo_img{width:68%}
.hm_phone img{width:22px;height:22px}
.hm_phone span{font-size:18px;line-height:22px}
.hm_phone a{line-height:22px}
.hm_btn a{margin:0 45px;padding:6px 10px 7px;font-size:14px}
.navbar-toggler span{width:32px;height:3px;margin-bottom:7px}
.header_module.front_page{padding:10px 0 12px}
.cbb_right{padding:12px 10px 62px 28px;width:83%;float:right}
.career_module h2{font-size:22px;width:85%;line-height:31px;margin-top:10px}
.career_module h4{font-size:23px;line-height:33px}
.cm_img{height:64px;margin-bottom:20px}
.cm_img img{height:64px}
.cbb_right{padding:33px 9px 63px 28px}
.cbb_right form input[type="submit"]{padding:7px;font-size:20px;width:100%;line-height:21px}
.f_cnt{font-size:12px;line-height:18px;margin-bottom:0;padding-left:4px}
.cn_round{left:190px;right:140px;top:12px}
.cbb_right form input[type="text"],.cbb_right form input[type="tel"],.cbb_right form input[type="email"],.cbb_right form select{font-size:15px}
.cbbr-field{margin-right:0}
.cbb_right h2{width:100%;margin-top:0;margin-bottom:5px}
.cbb_right form{padding-top:0}
.cm_img_1 img,.cm_img_2 img{padding-left:45px}
.cm_img_3 img{padding-left:8px}
.cbb_right form input[type="submit"]{margin-left:2px}
.whoare_module{padding-top:140px;margin-top:180px}
.whoare_module p{font-size:17px;line-height:1.5}
.wmb_conts{padding-right:65px}
.programe_module p{font-size:17px;line-height:1.5}
.am_block h2{font-size:45px;line-height:1.5}
.am_block{padding:28px 0 35px}
.am_blog{padding-top:0}
.advantage_module{padding:80px 0 33px}
.advantage_module ul{margin-bottom:0}
.advantage_module ul li{font-size:16px;line-height:1.5;padding-bottom:20px}
.amb_left,.amb_right{margin-top:75px}
.am_btn{font-size:15px;padding:14px 30px;margin-top:30px}
.am_blog{margin-bottom:90px}
.am_small p{font-size:13px;line-height:1.5;margin-bottom:0}
.fmb_link img{height:57px}
.footer_module{padding-top:36px}
.fm_menu ul li{margin-bottom:20px}
.fmb_social ul li{width:39px;height:39px}
.fmb_social ul li a{font-size:20px;line-height:39px}
.fm_copyright p{font-size:14px}
.fm_copyright{padding-top:60px}
.footer_module{padding-bottom:25px}
.fmb_social h3{font-size:17px;margin-bottom:25px}
.fm_logo{width:100%}
.pb_blog ul li{font-size:16px;line-height:1.5}
.whoare_module .wmb_link{font-size:25px;line-height:1.5}
.pm_corner{bottom:-120px;right:-26px;width:450px;height:450px}
.programe_module{margin-bottom:0}
.am_block h2{margin-bottom:0}
}
@media(max-width:767px) {
.career_module h2{margin-top:10px;text-align:center}
.career_module h4{text-align:center;margin-bottom:20px;font-size:19px;line-height:1.5}
.am_block h2{font-size:32px;line-height:1.5}
.am_blog{padding-top:20px;margin-bottom:50px}
.advantage_module ul li{padding-bottom:15px}
.advantage_module{padding:125px 0 15px;margin-bottom:0}
.fm_logo{margin-bottom:25px}
.fm_menu ul li{margin-bottom:20px}
.fmb_social h3{margin-bottom:15px;padding-top:20px}
.fm_copyright{padding-top:40px}
.fm_copyright p{font-size:15px}
.am_btn{margin-top:15px;padding:20px 30px;text-align:center;font-size:18px}
.programe_module h2{font-size:32px;line-height:1.5;text-align:center}
.programe_module p{font-size:17px;line-height:1.5}
.pm_block{padding-left:0}
.programe_module p{margin-bottom:20px}
.pb_blog h3{margin-top:20px}
.pm_corner{bottom:-125px;left:50%;transform:translateX(-50%);right:auto;width:250px;height:250px;border-radius:50%;border:2px solid #fabb17}
.btn-red{margin-top:40px;margin-bottom:100px;font-size:18px;text-align:center}
.wm_block{background-image:none}
.whoare_module h2{font-size:32px;line-height:1.5;margin-top:20px;margin-bottom:25px;text-align:center}
.whoare_module p{font-size:17px;line-height:1.5}
.wmb_conts{margin-right:0;padding-right:0;padding-bottom:20px;margin-bottom:0}
.whoare_module .wmb_link{font-size:21px;line-height:1.5}
.whoare_module{margin-top:30px;padding-top:10px}
.cbb_right{padding:25px 10px}
.cbbr-field{margin-right:0}
.cm_img{height:auto;text-align:center}
.header_module.front_page{padding:7px 0 20px}
.header_module{padding:7px 0 20px}
.cbb_right form input[type="submit"]{font-size:20px;line-height:20px;padding:12px 50px;width:100%;margin-left:0}
.header_module .navbar-brand img{max-width:220px}
.hm_phone span{display:none}
.hm_btn a{margin:0 30px;padding:6px 10px 8px;font-size:15px;border-radius:0px}
.cbb_right:after{width:120%}
.amb_cnt{padding-top:15px}
.am_small p{line-height:1.5;font-size:13px}
.cn_round{position:initial}
.cn_round img{max-width:300px;display:block;margin:0 auto}
.am_block{padding:28px 0 0}
.advantage_module ul li{font-size:17px}
.pb_blog ul li{font-size:16px}
.fm_logo img{width:75%}
.fmb_social ul li{width:39px;height:39px}
.fmb_social ul li a{color:#424242;font-size:20px;line-height:39px}
.cm_img{height:50px;margin-bottom:15px}
.cm_img img{height:50px}
.dv-wm{display:none}
.mv-wm{display:block}
.wmb_conts img{width:300px;display:block;margin:0 auto;margin-bottom:15px}
.sidebar{width:100%!important}
}
@media(max-width:560px) {
.header_module .navbar-brand img{max-width:205px}
.hm_btn a{margin:0 16px}
}
@media (max-width:480px) {
.header_module .navbar-brand img{max-width:180px}
.hm_btn a{margin:0 13px;padding:15px 7px;font-size:14px}
.navbar-toggler span{width:35px}
}
@media (max-width:420px) {
.header_module .navbar-brand img{max-width:155px}
.navbar-brand{margin-right:5px}
.hm_btn a{padding:7px 10px;font-size:12px}
.navbar-toggler span{margin-bottom:5px}
.hm_phone img{margin-right:1px}
.navbar-toggler[aria-expanded="true"] span:first-of-type{transform:rotate(45deg) translate(6px,6px)}
.navbar-toggler[aria-expanded="true"] span:last-of-type{transform:rotate(-45deg) translate(7px,-7px)}
}
@media(max-width:359px) {
.navbar-brand{margin-right:5px;margin-bottom:8px}
.hm_phone{margin-bottom:8px}
.cn_round img{max-width:270px}
.wmb_conts img{width:270px}
}
.sidebar{width:430px;height:100vh;background:#fff;padding:60px 30px;position:fixed;top:0;right:-100%;-webkit-transition:all .5s ease-in-out;transition:all .4s ease-in-out;-webkit-transition:all .4s ease-in-out;-moz-transition:all .4s ease-in-out;z-index:99}
.opened{right:0;-webkit-box-shadow:-10px 0 50px 0 rgba(0,0,0,0.1);box-shadow:-10px 0 50px 0 rgba(0,0,0,0.1)}
.sidebar .closesidebar{position:absolute;top:30px;right:60px;cursor:pointer}
.sidebar .closesidebar svg{fill:#424242;width:30px;height:30px}
.sidebar .sb-logo{margin:30px 0}
.new::after{content:"\f068"!important}
.disabled-formbtn{opacity:.6;cursor:not-allowed}
.navbar-nav .nav-item a.login-btn{display:inline-block;width:150px;text-align:center;padding:7px 0;color:#fff;background:#0e0e0e;border:1px solid #0e0e0e;transition:all .2s ease-in-out;margin-top:10px;margin-left:15px}
.navbar-nav .nav-item a.login-btn:hover{border:1px solid #0e0e0e;color:#000;background-color:#fff}
.nav_link_subUl{padding-left:30px;display:none}
.nav_link_subUl li{list-style-type:none}
.header_module.shrink{position:fixed;width:100%}
.owl-dots{padding-top:5px;bottom:40px}
.owl-nav{display:flex;align-items:center}
.owl-dots{display:flex;align-items:center;justify-content:center}
.grecaptcha-badge{z-index:9999}
.missed_span{display:flex;flex-direction:column;justify-content:center;text-align:center}
.give_call{text-transform:uppercase;font-size:10.5px!important;font-weight:600!important}
.missed_span span{line-height:normal}