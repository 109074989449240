body{font-size:17px;color:#2d2d2d;line-height:1.5}
a{color:#026ab2;text-decoration:underline;transition:all .3s ease}
a:hover{text-decoration:none}
.breadcrumbs_module{padding:15px 0}
.breadcrumbs_module ul{padding-left:0;list-style:none;margin-bottom:0}
.breadcrumbs_module ul li{color:#383838;display:inline-block;position:relative;padding-right:4px;margin-right:13px;font-size:16px;font-style:italic;font-weight:400;line-height:26px}
.breadcrumbs_module ul li+li:after{position:absolute;content:">";top:0;left:-12px}
.breadcrumbs_module ul li a{color:#383838;text-decoration:none}
.breadcrumbs_module ul li a:hover,.breadcrumbs_module ul li a:focus{color:#000}
.pi_heading{font-size:60px;color:#2d2d2d;font-weight:400;line-height:1.1;text-transform:uppercase}
.pi_content{margin-top:70px;max-width:70%}
.pi_content h3{font-size:26px;color:#2d2d2d;font-weight:700;line-height:1.5}
.pi_posr{position:relative}
.pi_abs{position:absolute;top:7px;right:15%;z-index:-1}
.page_intro_module{padding-bottom:82px}
.posr{position:relative}
.am_small p{margin-bottom:0}
#apply-now-btn{position:fixed;top:20%;right:10px;background:url(../img/icon-apply-now.png);background-size:70px 61px;width:70px;height:61px;display:block;text-decoration:none;-webkit-border-radius:0;-moz-border-radius:0;border-radius:0;display:none;-webkit-transition:all .3s linear;-moz-transition:all .3s ease;-ms-transition:all .3s ease;-o-transition:all .3s ease;transition:all .3s ease;cursor:pointer;z-index:9}
#apply-now-btn:hover{opacity:.8}
#return-to-top{z-index:9}
.cmsc_image{position:absolute;right:-54%;z-index:-1;top:auto}
.cmsc_image .slick-slide img{display:block;width:71%}
.cm_cnt{position:absolute;top:0;width:100%}
.sms_image .slick-list,.sms_image .slick-list .slick-track,.sms_image .slick-list .slick-track .cn_round,.sms_image .slick-list .slick-track .cn_round img{height:100%}
.career_module .slick-dots li{border:2px solid #c53036;border-radius:50%;width:15px;height:15px}
.career_module .slick-dots li:focus,.career_module .slick-dots li:hover{background-color:#c53036}
.career_module .slick-dots li.slick-active{background-color:#c53036}
.career_module .slick-dots li button{width:15px;height:15px}
.cm_slider .owl-nav{bottom:40px;position:absolute;width:100%;text-align:center;height:30px}
body .owl-carousel .owl-nav button.owl-next,body .owl-carousel .owl-nav button.owl-prev{width:30px;height:30px;display:inline-block;background:#ffbc3b;border-radius:50%;line-height:30px}
body .owl-carousel .owl-nav button span{font-size:30px;display:inline-block;line-height:24px;color:#000}
body .owl-carousel .owl-item .cm_img img{height:64px;width:auto}
body .cm_slider .owl-dots{position:absolute;bottom:60px;text-align:center;width:100%;height:30px}
body .cm_slider .owl-dots button{width:15px;height:15px;border:2px solid #c53036;border-radius:50%;line-height:30px;vertical-align:middle;margin:0 5px}
body .cm_slider .owl-dots button.active{background-color:#c53036}
body .cm_slider .owl-nav{bottom:-30px;position:absolute;width:100%;text-align:center;height:30px}
body .owl-carousel .owl-nav button.owl-next{position:absolute;right:43%;z-index:9}
body .owl-carousel .owl-nav button.owl-prev{position:absolute;left:43%;z-index:9}
.cbb_right form select option{background:#eee}
.cbb_right form select option:hover{color:#000}
.pb_blog h3{margin-bottom:20px}
.programe_module h2{color:#383838}
.pm_block{padding-bottom:35px}
.cms_banner_2 .cms_cont{padding:40px 0}
.cmsb_2{color:#000;font-weight:700;font-family:"Lato";font-size:34px;line-height:1.5}
.cmsb_head{font-size:26px;line-height:1.5;letter-spacing:-1px;font-weight:500;color:#000;margin-bottom:15px}
.slide_cnt h3{font-size:23px;line-height:1.2;color:#f7b817;font-weight:700;border-bottom:1px solid #a01d21;margin-bottom:0;display:block;height:100%}
.slide_cnt h3 span{position:relative;background:#a01d21;padding:5px 20px 5px 10px;display:inline-block}
.slide_cnt h3 span:before{content:"";display:inline-block;width:0;height:0;border-style:solid;border-width:0 11px 38px 0;border-color:transparent #eee transparent transparent;position:absolute;right:0;top:0}
.slide_cnt h3 span:after{content:'';position:absolute;top:0;bottom:0;z-index:9;background-color:#a11e23}
.cmsb_head{margin-bottom:30px}
.slide_cnt p{font-size:22px;line-height:1.5;color:#a11e23;font-weight:500;padding-left:10px}
.b2_cm_img{position:absolute;top:50%;transform:translateY(-50%);right:0;right:-50px;left:-35px}
.cms_banner_3 .cms_cont{padding:40px 0}
.cmsb_3{color:#000;font-weight:700;font-family:"Lato";font-size:25px}
.b3_cm_img{position:absolute;top:50%;transform:translateY(-50%);right:0;right:-20px;left:-15px}
.smsbc_blog{display:flex;flex-wrap:wrap;align-items:center;margin-bottom:25px}
.smsbc_icon{width:75px}
.owl-carousel .owl-item .smsbc_icon img{width:auto;height:44px}
.smsbc_data{width:calc(100% - 75px)}
.smsbc_data p{margin-bottom:0;font-size:21px;color:#a01f23;font-weight:700}
.smsbc_data p a{color:#a01f23;text-decoration:none}
.smsbc_data p a:hover{color:#fdbb16}
.am_block h2{font-size:56px;color:#2d2d2d;font-weight:700;text-transform:uppercase;line-height:66px;text-align:center;margin-bottom:20px}
.am_block{padding:28px 0 43px}
.am_block p{font-size:17px;line-height:1.5;color:#2d2d2d;font-weight:400}
.amb_cnt p{font-size:17px;line-height:1.5}
.amb_cnt p:last-child{margin-bottom:0px}
.benefits_lists ul{list-style:none;padding-left:0}
.benefits_lists ul li{font-size:17px;line-height:1.5;color:#ac1a20;font-weight:700;background-image:url(../img/list_arrow.png);background-repeat:no-repeat;background-size:20px 20px;background-position:top 8px left;padding-left:30px;padding-bottom:15px;text-align:left}
.btn_primary{margin-top:15px;padding:15px 28px;margin-bottom:0;font-size:17px;color:#f4f4f4;font-weight:700;background-image:linear-gradient(90deg,#ab1f24 0%,#f04e29 100%);text-decoration:none;text-transform:uppercase;display:inline-block;margin-bottom:15px;transition:all .3s ease}
.btn_primary:hover{background-image:linear-gradient(90deg,#f04e29 0%,#ab1f24 100%);color:#fff;text-decoration:none}
.benefits-text{font-size:26px;line-height:1.5;color:#000;font-weight:700;margin-bottom:29px}
.apl_bnt{margin-top:20px;margin-bottom:80px}
.address_module{padding:0 0 100px}
.addbb_address ul{list-style:none;margin-bottom:0;padding-left:0}
.addbb_address ul li{display:block;font-size:26px;line-height:1.5}
.add_block h2{color:#2d2d2d;font-weight:700;text-transform:uppercase;font-size:45px;margin-bottom:36px}
.addbb_location img{width:122px}
.addbb_address{padding-top:25px}
.addbb_iframe iframe{width:350px;height:350px}
.add_block{position:relative}
.add_block:after{position:absolute;content:'';background-image:url(../img/address_background.png);background-repeat:no-repeat}
.pi_contact{text-align:left}
.pi_contact h3{font-size:2rem;line-height:1.015;margin-bottom:20px;font-weight:600}
.pi_contact p{font-size:1.25rem;line-height:1.5;color:#2d2d2d;font-weight:600}
.pi_contact .link-customer{color:#000;text-decoration:none;transition:.3 delay;font-weight:700;font-size:1.5rem}
.pi_contact .link-customer:hover,.pi_contact .link-customer:focus{text-decoration:underline}
.addbb_location{background:#febb15;padding:2.5rem 1.5rem 2rem;text-align:center;max-width:50%;margin:2% auto}
.addbb_location address{margin-bottom:0}
.addbb_location h2{font-size:2.5rem;line-height:1.015;margin-bottom:20px;text-transform:uppercase;font-weight:600}
.addbb_location p{font-size:1.25rem;line-height:1.5;color:#2d2d2d;font-weight:400}
.addbb_location .link-submit{color:#026ab2;text-decoration:underline;transition:.3 delay;font-weight:600}
.addbb_location .link-submit:hover,.addbb_location .link-submit:focus{text-decoration:none}
.addbb_location .link-customer{color:#000;text-decoration:none;transition:.3 delay;font-weight:600}
.addbb_location .link-customer:hover,.addbb_location .link-submit:focus{text-decoration:underline}
.pi_faqs h1{text-transform:initial;margin-bottom:70px}
.pi_accordian .card{padding:0 0 6px;background-color:#fefcfd;margin-bottom:30px;position:relative;border-radius:0;border:0}
.pi_accordian{padding-left:52px;padding-top:40px;padding-bottom:50px}
.pia_number{position:absolute;width:52px;height:52px;top:0;left:-52px;line-height:52px;text-align:center;background:#c53036;font-size:26px;line-height:2;color:#eee;font-weight:700}
.pi_accordian .card-header{background-color:transparent;padding:0;border:0;position:relative;z-index:9}
.pi_accordian .card-header:not(.collapsed){margin-bottom:20px}
.pi_accordian .card-header:after{font-family:'FontAwesome';content:"\f068";top:8px;right:8px;width:39px;height:39px;background:#dfdede;position:absolute;line-height:39px;text-align:center;font-size:20px;z-index:-1}
.pi_accordian .card-header.collapsed:after{content:"\f067"}
.pi_accordian .card-header .card_title{text-align:left;font-size:20px;line-height:1.5;color:#2d2d2d;font-weight:700;text-decoration:none;width:100%;display:block;padding:15px 70px 10px 20px;cursor:pointer}
.pi_accordian .card-header .card_title:hover{text-decoration:none}
.pi_accordian .card-header .card_title:focus{box-shadow:none;text-decoration:none}
.pi_accordian .card-body{font-size:17px;line-height:1.5;color:#2d2d2d;font-weight:400;padding:0 70px 0 20px}
.pi_accordian .card-body .pia_cont{margin-bottom:25px}
.pi_accordian .card-body ol,.pi_accordian .card-body ul{padding-left:17px;margin-bottom:0}
.page_intro_module.pi_faqs{padding-bottom:33px}
.our_programs_module h2{font-size:45px;color:#2d2d2d;font-weight:700;line-height:1.5}
.our_programs_module h3{font-size:38px;color:#c53036;font-weight:700;line-height:1.5}
.our_programs_module p{font-size:17px;line-height:1.5}
.opmb_col{margin-bottom:30px}
.opm_table table tr th{padding:3px 10px;vertical-align:middle;border-bottom:2px dotted #fbc233;border-top:0;line-height:1.5;font-size:18px;color:#c53036;font-weight:900}
.opm_table table tr td{padding:3px 10px;vertical-align:middle;border-bottom:2px dotted #fbc233}
.opm_table table tr th:first-child{width:40%}
.opm_table table tr th:nth-child(2){width:50%}
.opm_table table tr th:last-child{width:10%}
.our_programs_image{margin-top:70px}
.op_courses_module .nav-pills{display:none}
.op_courses_module .card{background-color:transparent}
.op_courses_module .nav-pills .nav-link{font-weight:700;background-color:#fdbb16;text-decoration:none;border-radius:0;font-size:15px;line-height:1.2;border-bottom:5px solid #fff;color:#3c3b3b}
.op_courses_module .nav-pills .nav-link:last-child{border-bottom:0}
.opct_head{display:block;text-transform:uppercase}
.opct_subtitle{display:block}
.no_pr{padding-right:0}
.no_pl{border-top:14px solid #fdbb16;border-right:11px solid #aaa9a9;border-bottom:10px solid #646363;border-left:11px solid #fdbb16}
.op_courses_module h5{font-size:14px;line-height:1.2;color:#2d2d2d;font-weight:900;text-transform:uppercase;margin-bottom:4px}
.op_courses_module .card-body{padding:20px}
.op_courses_module .card-body p{margin-bottom:10px;font-size:1rem;line-height:1.35;color:#2d2d2d}
.op_courses_module .card-body ul{padding-left:0}
.op_courses_module .card-body ul li{font-size:1rem;line-height:1.35;list-style:none;padding-left:18px;position:relative;margin-bottom:7px}
.op_courses_module .card-body ul li:after{position:absolute;width:6px;height:6px;top:8px;background:#c53036;content:'';left:0}
.op_courses_module .nav-pills .nav-link.active,.op_courses_module .nav-pills .show>.nav-link{background:#c53036;color:#fff}
.approximately p{margin-bottom:25px;font-size:14px}
.op_courses_module{padding:40px 0 33px}
.opm_enrolment{padding:70px 0;color:#fff}
.opme_block{background-color:#c53036;padding:35px 50px}
.opme_block h2{font-size:40px;color:#eee;font-weight:700;margin-bottom:25px}
.opme_tagline p{font-size:24px;line-height:1.5;color:#383838;font-weight:400;text-align:center}
.opme_tagline{margin-top:70px}
.opme_tagline a{color:#026ab2}
.op_courses_module .card.tab-pane .card-header{padding:0}
.op_courses_module .card.tab-pane .card-header a{display:block;padding:12px 20px}
.op_courses_module .card.tab-pane .card-header{position:relative}
.op_courses_module .card.tab-pane .card-header a{padding:12px 30px 12px 20px;z-index:9}
.op_courses_module .card.tab-pane .card-header .fa{position:absolute;top:50%;right:10px;transform:translateY(-50%)}
.opct_tagline{font-size:13px}
.opme_block ul{list-style:none;padding-left:0}
.opme_block ul li{margin-bottom:15px}
.opm_step:after{height:16px;width:15px;background-size:16px 15px}
.opm_step{font-size:26px;line-height:1;color:#fdbb16;font-weight:700;position:relative;width:105px;display:inline-block;margin-right:25px}
.opm_step:after{content:'';position:absolute;top:50%;transform:translateY(-50%);height:22px;width:21px;background-image:url(../img/step_arrow.png);background-repeat:no-repeat;right:0;background-position:center center}
.opm_img{width:60px;height:60px;display:inline-block}
.opm_title{font-size:22px;line-height:1.5;padding-left:18px}
.opme_btn{text-align:center;margin-top:35px}
.opme_btn a{font-size:21px;color:#f4f4f4;font-weight:700;text-decoration:none;text-transform:uppercase;background-image:linear-gradient(90deg,#ad0007 0%,#f04e29 100%);border:1px solid #f26522;padding:12px 30px;display:inline-block}
.thank_you_module{padding-bottom:275px}
.thank_you_module .pi_abs{top:auto;right:auto;bottom:auto;left:50%;transform:translateX(-50%)}
.thank_you_module{padding-top:56px}
.navbar-toggler span{display:block;width:32px;height:2px;margin:6px auto;background:#484847;transition:all .6s cubic-bezier(0.250,0.100,0.250,1.000)}
.navbar-toggler:hover span,.navbar-toggler:focus span{background:#484847}
.navbar-toggler[aria-expanded="true"] span:first-of-type{transform:rotate(45deg) translate(6px,6px)}
.navbar-toggler[aria-expanded="true"] span:nth-of-type(2){opacity:0}
.navbar-toggler[aria-expanded="true"] span:last-of-type{transform:rotate(-45deg) translate(5px,-5px)}
.navbar-toggler[aria-expanded="false"] span{transform:none;opacity:1}
.navbar-toggler:hover span.middle-navmenu{width:70%}
@media (min-width: 768px) {
.sms_image{height:590px;text-align:center}
.sms_image .slick-list .slick-track .cn_round img{display:inline-block;margin:0 auto}
.cms_banner_2 .cncb_image{display:none}
.cms_banner_3 .cncb_image{display:none}
.cmsb_2{font-size:21px}
.cmsb_head{margin-bottom:20px;font-size:18px}
.slide_cnt h3{font-size:20px}
.slide_cnt p{font-size:17px}
.b2_cm_img{right:0}
.cmsb_3{font-size:16px;line-height:1.5;margin-bottom:25px}
.smsbc_data p{font-size:16px}
.smsbc_icon{width:60px}
.b3_cm_img{right:0}
.smsbc_icon{width:50px}
.owl-carousel .owl-item .smsbc_icon img{height:35px}
.smsbc_data{width:calc(100% - 50px)}
.cms_banner_3 .cms_cont{padding:15px 0}
.career_module{height:550px}
.whoare_module{margin-top:20px;padding-top:20px}
.cmc_posa{position:absolute;right:0;z-index:9}
.cms_cont{width:66.66%;position:absolute;top:0}
.cmc_posr{position:absolute;left:0}
.cncb_image img{display:block;margin:0 auto;width:67%}
.cm_cnt{position:absolute;top:0;width:100%;bottom:0;height:550px}
.cmc_posr{position:absolute;left:0;height:100%}
.cm_slider,.cm_slider .slick-list,.cm_slider .slick-list .slick-track{height:100%}
.cncb_image{position:absolute;bottom:0}
.career_module .slick-dots{bottom:40px}
.cm_slider .owl-dots{bottom:40px}
body .owl-carousel .owl-nav button.owl-prev{left:38%}
body .owl-carousel .owl-nav button.owl-next{right:38%}
body .owl-carousel .owl-item .cm_img img{height:64px;width:auto}
body .cm_slider .owl-nav{bottom:40px;position:absolute;width:100%;text-align:center;height:30px}
body .owl-carousel .owl-item .cncb_image{position:initial}
body .owl-carousel .owl-item .cncb_image img{display:block;margin:0 auto;width:77%;margin-top:2%}
.am_block h2{font-size:45px;line-height:55px}
.am_block{padding:28px 0 15px}
.benefits-text{font-size:22px}
.apl_bnt{margin-top:15px;margin-bottom:60px}
.amb_cnt p{font-size:17px}
.pi_heading{font-size:45px}
.pi_abs{top:auto;bottom:-15px;right:0}
.pi_content{margin-top:40px;max-width:100%}
.pi_content h3{font-size:24px}
.f_cnt{padding-top:10px;font-size:12px;line-height:1.5;margin-bottom:0}
.ambl_lists ul{text-align:right}
.ambl_lists ul li{display:inline-block}
.pi_banner{display:none}
.pi_abs{display:block}
.about_module{padding-bottom:39px}
.dv_hide{display:none}
.dv-show{display:block}
.f_cnt{font-size:10px;line-height:1.2}
.cbbr-field{margin-bottom:5px}
.pi_contact_module .pi_abs{top:auto;bottom:-33px;right:17%}
.pi_faqs h1{margin-bottom:15px}
.pi_faqs .pi_abs{bottom:-30px;right:26%;width:72%}
.pi_first_it_careers_module .pi_img{position:absolute;bottom:0}
.pi_first_it_careers_module .pi_abs{top:auto;bottom:-25px;right:0;width:90%}
.pi_first_it_careers_module .pi_content{max-width:100%;margin-top:40px}
.pi_first_it_careers_module .pi_content h3{font-size:21px}
.op_courses_module .nav-pills{display:flex;margin-bottom:30px}
.op_courses_module .nav-pills .nav-link{font-size:13px;padding:5px 8px}
.op_courses_module .card{border:none}
.op_courses_module .card .card-header{display:none}
.op_courses_module .card .collapse{display:block}
.no_pl{padding-left:0}
.opme_block{padding:30px 0}
.opme_block h2{font-size:28px}
.opme_block ul{padding-left:0}
.opme_block ol{counter-reset:list-number;list-style:none;padding-left:0}
.opme_block ol li{line-height:1.5;color:#eee;font-weight:700;padding-bottom:10px;font-size:16px}
.opme_block ol li:last-child{padding-bottom:0}
.opme_block ol li:before{counter-increment:list-number;content:counter(list-number) ". ";padding-right:5px;font-size:18px}
.opme_block h2{font-size:28px;margin-bottom:25px}
.opme_tagline p{font-size:18px}
.op_courses_module .card.tab-pane .card-header a.collapsed{background:#fdbb16}
.opmt_switch .our_programs_image{margin-top:0}
.opm_step:after{height:16px;width:15px;background-size:16px 15px}
.switch_careers_module .pi_abs{width:59%;top:auto;bottom:-7%;right:20%}
.opm_step{font-size:20px;line-height:1;color:#fdbb16;font-weight:700;position:relative;width:85px;display:inline-block;margin-right:15px}
.opm_img{width:45px;height:45px;display:inline-block}
.opm_title{font-size:17px;line-height:1.5;padding-left:10px;font-weight:700}
.thank_you_module{padding-bottom:169px}
.cbb_right{padding:10px 10px 20px}
.programe_module p{margin-bottom:20px}
.cbb_right form input[type="text"],.cbb_right form input[type="tel"],.cbb_right form input[type="email"],.cbb_right form select{font-size:14px}
.pm_corner{bottom:-95px;right:-24px;width:370px;height:370px}
.btn-red{padding:14px 30px;font-size:15px}
.am_blog{margin-bottom:70px}
}
@media (max-width: 1140px) {
.give_call{font-size:13.5px!important}
}
@media (min-width: 992px) {
.sms_image{height:630px;text-align:center}
.sms_image .slick-list .slick-track .cn_round img{display:inline-block;margin:0 auto}
.career_module{height:600px}
.cm_cnt{height:600px}
body .owl-carousel .owl-item .cncb_image img{margin-top:0;width:67%;margin-right:14%}
body .cm_slider .owl-dots{bottom:40px}
body .owl-carousel .owl-nav button.owl-prev{left:41%}
body .owl-carousel .owl-nav button.owl-next{right:41%}
.f_cnt{font-size:11px;line-height:1.4}
.pm_corner{bottom:-110px;right:-26px;width:420px;height:420px}
.cbb_right{padding:25px 32px 35px 30px}
.cmsb_2{font-size:30px}
.cmsb_head{margin-bottom:25px;font-size:24px}
.slide_cnt h3{font-size:22px}
.slide_cnt p{font-size:19px}
.cmsb_3{font-size:22px;margin-bottom:40px}
.smsbc_icon{width:60px}
.owl-carousel .owl-item .smsbc_icon img{height:45px}
.smsbc_data{width:calc(100% - 60px)}
.b3_cm_img{left:-15px}
.smsbc_data p{font-size:20px}
.am_block h2{font-size:45px}
.pi_content{margin-top:70px;max-width:90%}
.pi_content h3{font-size:26px}
.pi_heading{font-size:50px}
.amb_cnt{padding-right:3%}
.cbb_right h2{font-size:24px;text-align:center}
.btn-red{margin-top:20px;padding:14px 36px}
.pi_contact_module .pi_abs{top:auto;bottom:-36px;right:18%}
.pi_faqs .pi_content{margin-top:50px;max-width:45%}
.pi_faqs .pi_abs{bottom:-40px;right:27%;width:68%}
.pi_first_it_careers_module .pi_content{max-width:40%;margin-top:140px}
.opme_block ol li{line-height:1.5;color:#eee;font-weight:700;padding-bottom:25px;font-size:17px}
.opme_block ol li:last-child{padding-bottom:0}
.opme_block ol li:before{counter-increment:list-number;content:counter(list-number) ". ";padding-right:20px;font-size:22px}
.op_courses_module .nav-pills .nav-link{font-size:15px;padding:7px 8px}
.opme_tagline p{font-size:20px}
.op_courses_module .nav-pills{margin-bottom:60px}
.pi_first_it_careers_module .pi_abs{top:auto;bottom:-30px;right:1%;width:90%}
.pi_first_it_careers_module .pi_img{position:absolute;bottom:0}
.switch_careers_module .pi_content{margin-top:110px;max-width:49%}
.switch_careers_module .pi_abs{top:auto;bottom:-5%;width:50%;right:26%}
.opme_block h2{font-size:42px}
.opm_step{font-size:22px;width:100px;margin-right:25px}
.opm_img{width:55px;height:55px}
.opm_title{font-size:22px;line-height:1.5;padding-left:15px}
.opme_block{padding:30px}
.opm_step:after{height:20px;width:19px;background-size:20px 19px}
.thank_you_module{padding-bottom:230px}
.cbb_right form input[type="text"],.cbb_right form input[type="tel"],.cbb_right form input[type="email"],.cbb_right form select{font-size:15px}
.am_blog{margin-bottom:110px}
}
@media (min-width:1200px) {
.cmsb_2{font-size:30px}
.b3_cm_img{right:-25px}
.b2_cm_img{right:-50px}
.cms_banner_3 .cms_cont{padding:40px 0}
.whoare_module{margin-top:0;padding-top:60px}
.cmc_posa{position:absolute;right:0;z-index:99}
body .owl-carousel .owl-item .cncb_image img{display:block;margin:0 auto;width:56%;margin-right:18%}
.cms_cont{width:66.66%;position:absolute;top:0}
.career_module{height:600px}
.cmc_posr{position:absolute;left:0}
.cncb_image{position:initial;bottom:auto}
.cm_cnt{height:600px}
.pm_corner{bottom:-120px;right:-40px;width:520px;height:520px}
.sms_image .slick-list .slick-track .cn_round img{height:100%;display:inline-block;text-align:right}
.am_block h2{font-size:45px;line-height:1.5}
.am_block{padding:28px 0 35px}
.am_block h2{margin-bottom:0}
.benefits-text{font-size:26px}
.amb_cnt{padding-right:8%}
.pi_abs{top:7px;right:15%;bottom:auto}
.pi_content{max-width:70%}
.pi_about_module .pi_abs{top:3%;right:5%;bottom:auto;width:96%}
.pi_about_module .pi_img{width:85%}
.pi_about_module .pi_content{max-width:66%}
.about_module{padding-bottom:33px}
.pi_contact{max-width:100%;margin-top:110px;text-align:left}
.pi_contact_module .pi_abs{top:7px;bottom:auto;right:13%;width:85%}
.pi_faqs .pi_abs{bottom:-44px;right:23%;width:63%}
.pi_faqs .pi_img{position:absolute;bottom:0}
.pi_faqs .pi_content{margin-top:130px;max-width:35%}
.pi_first_it_careers_module .pi_content{max-width:40%;margin-top:140px}
.pi_first_it_careers_module .pi_content h3{font-size:26px}
.op_courses_module .nav-pills .nav-link{padding:8px 16px}
.opme_tagline p{font-size:24px}
.pi_first_it_careers_module .pi_abs{width:81%}
.opme_block{padding:35px 50px}
.opme_block ol li{padding-bottom:10px}
.switch_careers_module .pi_content{max-width:40%;margin-top:95px}
.switch_careers_module .pi_abs{top:10px;bottom:auto;width:58%;right:20%}
.opmt_switch .our_programs_image{margin-top:-70px}
.opm_step{font-size:26px;width:115px}
.opm_img{width:60px;height:60px}
.thank_you_module{padding-bottom:275px}
.f_cnt{font-size:11px;line-height:1.3}
}
@media (max-width:991px) {
.addbb_location{padding:2rem 1rem;max-width:100%;margin:2% auto}
.give_call{font-size:11.3px!important}
}
@media(max-width:767px) {
.cm_cnt{position:initial;width:auto}
.cm_img img{display:block;margin:0 auto}
.career_module .slick-dots{bottom:-15px}
.cmc_posa{margin-top:80px}
.cm_slider .owl-nav{bottom:-45px}
body .owl-carousel .owl-nav button.owl-prev{left:33%}
body .owl-carousel .owl-nav button.owl-next{right:33%}
body .cm_slider .owl-dots{bottom:-28px}
.pb_blog h3{font-size:26px;text-align:center;line-height:1.21}
.btn-red{margin-top:15px;margin-bottom:110px}
.am_block h2{margin-top:15px}
.pbb_btn{text-align:center}
body .owl-carousel .owl-item .cncb_image img{width:300px;margin:0 auto}
.cm_img{margin-bottom:20px}
.pm_block{padding-top:35px}
.cmsb_2{font-size:24px;text-align:center}
.b2_cm_img{display:none}
.slide_cnt{text-align:center;margin-bottom:25px}
.cncb_image{margin-bottom:25px}
.b3_cm_img{display:none}
.cmsb_3{font-size:21px;line-height:1.5;margin-bottom:20px}
.slide_cnt h3{text-align:left}
.am_block{padding:0 0 5px}
.am_block h2{font-size:32px;line-height:1.25}
.benefits_lists ul{margin-bottom:0}
.pi_content{margin-top:25px;max-width:100%;margin-bottom:40px}
.pi_heading{font-size:39px;margin-bottom:15px}
.benefits-text{font-size:22px}
.am_block .text-center img{width:300px}
.f_cnt{font-size:12px}
.page_intro_module{padding-bottom:40px}
.apl_bnt{margin-top:10px;margin-bottom:40px}
.pi_banner{display:block}
.pi_banner img{width:300px;display:block;margin:0 auto}
.pi_abs{display:none}
.about_module{padding-bottom:31px}
.dv_hide{display:block}
.dv-show{display:none}
.cbbr-field{margin-bottom:5px}
.cbb_right form input[type="text"],.cbb_right form input[type="tel"],.cbb_right form input[type="email"],.cbb_right form select{font-size:15px}
.pi_accordian .card-header .card_title{padding:10px 40px 10px 15px;font-size:17px}
.pi_accordian .card-header:after{width:25px;height:25px;line-height:25px;font-size:15px}
.pi_accordian .card{margin-bottom:20px}
.pi_accordian .card-header:not(.collapsed){margin-bottom:10px}
.pia_number{width:30px;height:30px;left:-30px;font-size:15px}
.pi_faqs h1{margin-bottom:15px}
.pi_accordian .card-body{padding:0 30px 0 20px}
.op_courses_module .tab-pane{display:block!important;opacity:1}
.op_courses_module .tab-content{padding:15px 0}
.op_courses_module .card.tab-pane{margin-bottom:15px;border-radius:0}
.op_courses_module .card.tab-pane:last-child{margin-bottom:0}
.op_courses_module .card.tab-pane .card-header a{color:#000;text-decoration:none;background:#fdbb16}
.opme_block ol{padding-left:15px}
.opme_block ol li{padding-bottom:10px}
.our_programs_image{margin-top:20px;margin-bottom:20px}
.our_programs_module h2{font-size:40px}
.opmb_col{margin-bottom:15px}
.opme_tagline p{font-size:19px}
.opme_block h2{font-size:30px;margin-bottom:20px}
.opme_block{padding:20px 10px 30px}
.opm_step{font-size:18px;width:75px;margin-right:15px}
.opm_img{width:45px;height:45px}
.opm_title{font-size:17px;padding-left:10px}
.opme_btn a{font-size:18px}
.thank_you_module{padding-bottom:0}
.ty_module{margin-top:0}
.pi_accordian{padding-left:30px}
.amb_cnt p:last-child{margin-bottom:30px}
}
@media(max-width:560px) {
body .owl-carousel .owl-nav button.owl-prev{left:31%}
body .owl-carousel .owl-nav button.owl-next{right:31%}
.opm_step{font-size:17px;width:70px;margin-right:10px;font-weight:700}
.opm_img{width:40px;height:40px}
.opm_title{font-size:15px;padding-left:10px;font-weight:700}
.opm_step:after{height:16px;width:15px;background-size:16px 15px}
.opme_btn a{font-size:17px}
}
@media (max-width:480px) {
.opm_title{display:block;width:100%;padding-left:0;padding-top:10px}
.addbb_location h2{font-size:2.25rem}
}
@media (max-width:420px) {
body .owl-carousel .owl-nav button.owl-prev{left:28%}
body .owl-carousel .owl-nav button.owl-next{right:28%}
}
@media(max-width:359px) {
.pi_banner img{width:270px}
.am_block .text-center img{width:270px}
.pi_heading{font-size:30px}
.pi_content h3{font-size:18px}
.cbb_right h2{font-size:24px;line-height:1.5}
.benefits-text{font-size:21px}
.btn_primary{font-size:14px}
body .owl-carousel .owl-item .cncb_image img{width:270px;margin:0 auto}
.opme_btn a{font-size:16px;padding:12px 20px}
.opme_block h2{font-size:28px}
}